<template>
  <div>
    <div>
      <k-header-section
        header-text="Credit/Debit Notes"
        :sub-nav-bar-items="navDatas"
        :total-items-count="totalItemsCount"
        :current-page-count="currentPageCount"
      >

        <div
          v-if="isSuperAdmin"
          class="d-flex justify-content-between inter-500-14"
        >
          <k-button
            variant="info"
            @click="onClickCreate"
          >
            <kingpin-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="ps-2 text-uppercase">{{ `${title} Notes` }}</span>
          </k-button>
        </div>
      </k-header-section>
    </div>
    <div>
      <router-view
        @update-total-count="updateTotalItems"
      />
    </div>
  </div>
</template>

<script>
import { KHeaderSection, KButton, KingpinIcon } from '@kingpin-global/kingpin-ui'
import notessNavBarContent from '@/assets/app-content/notes-navbar-content.json'
import { capitalize } from '@/@core/utils/format'
import { ROUTES } from '@/constants'

export default {
  name: 'Notes',
  components: {
    KHeaderSection,
    KButton,
    KingpinIcon,
  },
  data() {
    return {
      navDatas: notessNavBarContent.navBarItems,
      currentPageCount: 0,
      totalItemsCount: 0,
    }
  },
  computed: {
    title() {
      return capitalize(this.$route.name)
    },
    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin
    },
  },
  methods: {
    updateTotalItems(currentPageCount, totalItemsCount) {
      this.currentPageCount = currentPageCount
      this.totalItemsCount = totalItemsCount
    },
    onClickCreate() {
      this.$router.push({ path: `/${ROUTES.CREATE_NOTE.path}` })
    },
  },
}
</script>
